<template>
  <div class="receive_returns">
    <query-form
      class="content_block"
      label-width="120px"
      size="small"
      label-position="right"
      @search="getList('init')"
      @reset="handleReset"
      :keepOpenedRow="2"
    >
      <el-form-item label="退货状态：" :span="24">
        <check-tag
          v-model="advancedForm.refundState"
          @change="() => getList('init')"
        >
          <check-tag-item
            :value="i.value"
            :key="i.value"
            v-for="i in executionStatusGroup"
            :label="`${i.label}(${i.count || 0})`"
          ></check-tag-item>
        </check-tag>
      </el-form-item>
      <el-form-item label="销售单号：">
        <el-input
          v-model.trim="advancedForm.orderCode"
          clearable
          placeholder="请输入销售单号"
        ></el-input>
      </el-form-item>
      <el-form-item label="退货申请单号：">
        <el-input
          v-model.trim="advancedForm.afterCode"
          clearable
          placeholder="请输入退货申请单号"
        ></el-input>
      </el-form-item>
    </query-form>
    <expand-table
      class="content_block"
      :columnList="columnList"
      :tableData="dataList"
      :expandConfig="expandConfig"
      :pagination.sync="pagination"
      @size-change="getList"
      @current-change="getList"
      @sort-change="getList"
    />
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import ExpandTable from '@/components/ExpandTable';
import { queryLogisticsPage, queryRefundStateInfo, sure, queryLogisticsInfo } from '@/api/service';
import setTableCols from './configs';
import { executionStatusGroup } from '@/model/serviceReturns';

export default {
  name: 'ServiceReturns',
  mixins: [TablePaginationMixin],
  components: {
    ExpandTable,
  },
  data () {
    return {
      expandConfig: {
        accordion: true,
        lazy: true,
        loadMethod: this.getExpandContent,
      },
      columnList: setTableCols.call(this),
      dataList: [],
      advancedForm: {
        refundState: '',
      },
      executionStatusGroup,
    };
  },
  created () {
    this.$store.dispatch('changeDefaultActive', this.$route.name);

    if (this.$route.query.status || this.$route.query.status === 0) {
      this.advancedForm.refundState = this.$route.query.status + '';
    }

    this.getList();
    this.getCount();
  },
  methods: {
    getExpandContent ({ row }) {
      return queryLogisticsInfo({ id: row.id }).then(({ body }) => {
        body = body || [];

        if (row.type === 2) {
          body.forEach(v => {
            v.logisticsState += 1;
          });
        }

        row.expandData = body || [];
      });
    },
    getList (type) {
      if (type === 'init') {
        this.pagination = {
          total: 0,
          currentPage: 1,
          pageSize: 10,
        };
      }

      let { pageSize, currentPage: pageNum } = this.pagination;
      let orderBy = [
        {
          orderByProperty: 'lastUpdateTime',
          orderByType: 'DESC',
        },
      ];

      queryLogisticsPage({
        ...this.advancedForm,
        orderBy,
        pageSize,
        pageNum,
      }).then(({ body }) => {
        this.dataList = body.list;
        this.pagination.total = body.total;
      });
    },
    getCount () {
      queryRefundStateInfo().then(({ body }) => {
        for (let v of this.executionStatusGroup) {
          v.count = body[v.en];
        }
      });
    },
    handleReset () {
      for (let key in this.advancedForm) {
        this.advancedForm[key] = '';
      }

      this.pagination = {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };

      this.getList();
    },
    operate (type, row) {
      if (type === 'confirm') {
        this.$confirm('是否对该售后进行确认收货', '售后签收', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          sure({ id: row.id }).then(() => {
            this.$message({
              type: 'success',
              message: '收货成功!',
            });

            this.getList('init');
          });
        });
      } else {
        this.$router.push({
          path: '/service/returnDetail',
          query: {
            id: row.id,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.receive_returns {
  /deep/ .contract-option {
    color: #02a7f0;
    cursor: pointer;
    padding: 0 0.25em;
  }

  .el-range-editor--small.el-input__inner {
    width: 100%;
  }
}
</style>
