var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vxe-grid',{ref:"vxeTable",staticStyle:{"width":"100%"},attrs:{"resizable":"","autoResize":"","border":"full","size":_vm.size,"height":_vm.height,"max-height":_vm.maxHeight,"data":_vm.tableData,"row-id":_vm.rowKey,"showOverflow":"","align":"center","highlight-current-row":"","highlight-hover-row":"","loading":_vm.loading,"highlight-current-column":"","highlight-hover-column":"","scroll-y":_vm.scrollY,"columns":_vm.columnList,"seq-config":_vm.seqConfig,"row-class-name":_vm.getRowClassName,"toolbarConfig":_vm.toolbarConfig,"customConfig":_vm.customConfig,"sort-config":_vm.defaultSort,"expand-config":_vm.expandConfig,"tooltip-config":_vm.tooltipConfig},on:{"current-change":_vm.handleRowCurrentChange,"sort-change":_vm.handleSortChanged,"toolbar-button-click":_vm.handleToolbarButtonClick},scopedSlots:_vm._u([{key:"pager",fn:function(){return [(_vm.showPager && _vm.pagination && _vm.pagination.total)?_c('vxe-pager',{attrs:{"size":_vm.size,"current-page":_vm.pagination.currentPage,"page-sizes":[10, 20, 50, 100, 200],"page-size":_vm.pagination.pageSize,"layouts":[
        'PrevPage',
        'JumpNumber',
        'NextPage',
        'FullJump',
        'Sizes',
        'Total'
      ],"total":_vm.pagination.total},on:{"page-change":_vm.handleCurrentChanged}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }